import OfferCars from "./OfferCars";

const OffersWrapper = ({ isNew, title, deals }) => {
  return (
    <OfferCars
      isNew={isNew}
      title={title}
      offers={deals.posts}
    />
  );
};

export default OffersWrapper;
